import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  buttonURL?: string
  benefitsTitle?: string
  benefitsList?: string[]
  languageCode: string
  subtitle?: string
  title?: string
}

export const Info = memo(function Info({
  buttonURL,
  benefitsTitle,
  benefitsList,
  languageCode,
  subtitle,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {subtitle ? <SubTitle>{subtitle}</SubTitle> : null}
        {buttonURL ? (
          <CTA
            label={useVocabularyData('book-now', languageCode)}
            rel="noopener"
            target="_blank"
            URL={buttonURL}
          />
        ) : null}
      </Wrapper>
      <Benefits>
        {benefitsTitle ? (
          <FadeInUp>
            <BenefitsTitle>{benefitsTitle}</BenefitsTitle>
          </FadeInUp>
        ) : null}
        {benefitsList
          ? benefitsList.map((item, index) => (
              <Benefit key={index}>
                <FadeInUp>
                  {
                    // @ts-ignore
                    item.label
                  }
                </FadeInUp>
              </Benefit>
            ))
          : null}
      </Benefits>
    </Container>
  )
})

const Container = styled.div`
  @media (max-width: 1023px) {
    display: flex;
    flex-wrap: wrap;
  }
`

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 3.5rem 3.75rem;
  position: relative;
  z-index: 2;
  text-align: center;

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
    margin-top: 3rem;
    padding: 3rem 1.9375rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.75rem;
`

const SubTitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
  margin-top: 0.875rem;
`

const CTA = styled(Button)`
  display: block;
  margin-top: 2.5rem;
  padding-right: 3rem;
  padding-left: 3rem;
`

const Benefits = styled.div`
  margin-top: 2.5rem;
  padding: 0 2.5vw 3.75rem;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 5%;
    bottom: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 0;
    padding-bottom: 3rem;
  }
`

const BenefitsTitle = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 3rem;
  position: relative;
  text-align: center;
  z-index: 2;
`

const Benefit = styled.div`
  max-width: 180px;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 800;
  letter-spacing: 0.075rem;
  line-height: 0.9375rem;
  margin: 1.875rem auto 0;
  text-transform: uppercase;

  > div {
    padding-left: 1.25rem;
    position: relative;
    &:before {
      content: '';
      width: 0.5rem;
      height: 0.5rem;
      background: ${({ theme }) => theme.colors.variants.primaryLight1};
      position: absolute;
      top: 0.25rem;
      left: 0;
      transform: rotate(45deg);
    }
  }
`
